import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { VariantProps, cva } from 'class-variance-authority';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button, ButtonProps } from 'src/components/ui/button/button';
import { t } from 'i18next';
import { DialogCloseProps } from '@radix-ui/react-dialog';
import { cn } from 'src/lib/utils';

// if hardware acceleration is disabled, the animations will lag
// data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out
export const dialogVariants = cva(
  'tw-z-[1000] tw-grid tw-w-full tw-gap-4 tw-border tw-bg-background tw-p-6 tw-shadow-lg tw-duration-200 data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] sm:tw-rounded-lg',
  {
    variants: {
      size: {
        default: 'tw-max-w-xl',
        sm: 'tw-max-w-sm',
        md: 'tw-max-w-md',
        lg: 'tw-max-w-2xl',
        '2lg': 'tw-max-w-4xl',
        xl: 'tw-max-w-full md:tw-max-w-[648px] lg:tw-max-w-[920px] xl:tw-max-w-[1190px]',
        '2xl': 'tw-max-w-6xl',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
    VariantProps<typeof dialogVariants> {
  close?: boolean;
  withoutAnimations?: boolean;
}

interface DialogOverlayProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> {
  withoutAnimations?: boolean;
}

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  DialogOverlayProps
>(({ className, withoutAnimations, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'tw-fixed tw-inset-0 tw-z-[1000] tw-bg-black/70',
      {
        'data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0':
          !withoutAnimations,
      },
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export const DialogComposedClose = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Close>,
  ButtonProps & {
    iconSize?: number;
  }
>(({ className, iconSize = 20, size = 'icon', variant = 'ghost', ...props }, ref) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <DialogPrimitive.Close
          ref={ref}
          asChild
          className={cn(
            'tw-absolute tw-right-4 tw-top-4 tw-size-8 tw-rounded-sm tw-text-text tw-ring-offset-background tw-transition-opacity focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none',
            className,
          )}
        >
          <Button ref={ref} type={'button'} variant={variant} size={size} {...props}>
            <X size={iconSize} />
            <span className="tw-sr-only">{t('actions:close', { defaultValue: 'Close' })}</span>
          </Button>
        </DialogPrimitive.Close>
      </TooltipTrigger>
      <TooltipContent>{t('actions:close', { defaultValue: 'Close' })}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
));
DialogComposedClose.displayName = 'DialogComposedClose';

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, close = false, size, withoutAnimations = false, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay
      withoutAnimations={withoutAnimations}
      className={'tw-flex tw-items-center tw-justify-center'}
    >
      <DialogPrimitive.Content
        ref={ref}
        className={cn(dialogVariants({ size, className }))}
        {...props}
      >
        {children}
        {close && <DialogComposedClose />}
      </DialogPrimitive.Content>
    </DialogOverlay>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('tw-flex tw-items-center tw-justify-between', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('tw-flex tw-justify-end tw-space-x-2', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  // DialogPrimitive.Description
  <div ref={ref} className={cn('tw-text-muted-foreground', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DefaultDialogClose = ({ className, ...props }: DialogCloseProps) => (
  <DialogPrimitive.Close
    className={cn(
      'tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-ring-offset-background tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-accent data-[state=open]:tw-text-text',
      className,
    )}
    {...props}
  >
    <X className="tw-h-5 tw-w-5" />
    <span className="tw-sr-only">{t('actions:close', { defaultValue: 'Close' })}</span>
  </DialogPrimitive.Close>
);

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
