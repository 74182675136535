import React, { useCallback, useState } from 'react';
import { PaginationState } from '@tanstack/table-core/src/features/Pagination';
import { useTableStore } from 'src/components/Table/useTableStore';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'src/lib/services/api-query-params';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuContentProps,
  DropdownMenuItem,
  DropdownMenuProps,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button/button';
import { ChevronDown } from 'lucide-react';
import { cn } from 'src/lib/utils';

type Props = Omit<DropdownMenuProps, 'open' | 'onOpenChange'> & {
  menuContentProps?: DropdownMenuContentProps;
};

const TablePageSizeDropdown: React.FC<Props> = ({ menuContentProps, ...props }) => {
  const { t } = useTranslation('pagination');

  const { setPagination, pagination, totalItems } = useTableStore();
  const [isOpen, setOpen] = useState<boolean>(false);

  const resolveCurrentPage = useCallback<(prev: PaginationState, newLimit: number) => number>(
    (prev, newLimit) => {
      const currentPage = prev.pageIndex ?? DEFAULT_PAGE_INDEX;
      const currentLimit = prev.pageSize ?? DEFAULT_PAGE_SIZE;

      let seenRecords = (currentPage + 1) * currentLimit;
      if (seenRecords > totalItems) {
        seenRecords = totalItems;
      }

      let calculatedPageIndex = Math.ceil(seenRecords / newLimit) - 1;
      if (0 > calculatedPageIndex) {
        calculatedPageIndex = DEFAULT_PAGE_INDEX;
      }

      return calculatedPageIndex;
    },
    [totalItems],
  );

  return (
    <DropdownMenu {...props} onOpenChange={setOpen} open={isOpen}>
      <DropdownMenuTrigger asChild>
        <Button type={'button'} variant={'outline'} size={'iconEndSmResponsive'}>
          <span className={'tw-hidden md:tw-block'}>
            {t('show_entries', {
              replace: {
                limit: pagination.pageSize,
              },
            })}
          </span>
          <span className={'tw-block md:tw-hidden'}>{pagination.pageSize}</span>
          <ChevronDown
            size={16}
            className={cn('tw-transition-transform', isOpen && 'tw-rotate-180')}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent {...menuContentProps} side={'top'}>
        {[10, 20, 30, 40, 50].map((limit) => (
          <DropdownMenuItem
            key={limit}
            onClick={() =>
              setPagination((prev) => {
                return {
                  pageIndex: resolveCurrentPage(prev, limit),
                  pageSize: limit,
                };
              })
            }
          >
            {limit}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { TablePageSizeDropdown };
