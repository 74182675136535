import React from 'react';
import { Button } from 'src/components/ui/button/button';
import { OverlayIcon, useVideoPlayToggle } from 'src/features/video-player/video-player';
import { Pause, Play } from 'lucide-react';

type PlayButtonProps = React.ComponentProps<typeof Button> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, playing: boolean) => void;
};

export const TogglePlayButton: React.FC<PlayButtonProps> = ({ children, onClick, ...props }) => {
  const { playing, togglePlay } = useVideoPlayToggle();

  return (
    <Button
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        togglePlay();
        onClick?.(e, !playing);
      }}
    >
      {children || <OverlayIcon icon={playing ? Pause : Play} className={'tw-size-5'} />}
    </Button>
  );
};
