import React from 'react';
import { Button } from 'src/components/ui/button/button';
import { cn } from 'src/lib/utils';
import { OverlayIcon } from 'src/features/video-player/video-player';
import { Maximize, Minimize } from 'lucide-react';
import { useFullscreenToggle } from 'src/features/video-player/use-fullscreen-toggle';

type ToggleFullscreenButton = React.ComponentProps<typeof Button> & {
  onFullscreenChange?: (fullscreen: boolean) => void;
};

export const ToggleFullscreenButton: React.FC<ToggleFullscreenButton> = ({
  children,
  onFullscreenChange,
  onClick,
  className,
  ...props
}) => {
  const { toggleFullscreen, fullscreen } = useFullscreenToggle();

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await toggleFullscreen();
    onClick?.(e);
  };

  return (
    <Button {...props} className={cn(className)} onClick={handleClick}>
      {children || <OverlayIcon icon={fullscreen ? Minimize : Maximize} />}
    </Button>
  );
};
