import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertDescription, AlertTitle } from 'src/components/ui/alert';
import { XCircle } from 'lucide-react';
import { If } from 'src/components/If';
import { Card, CardContent } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button/button';
import { cn } from 'src/lib/utils';

type CardErrorFallbackProps = React.ComponentProps<typeof Card> & {
  error: any;
  description?: React.ReactNode;
  resetErrorBoundary: () => any;
  cardContentProps?: React.ComponentProps<typeof CardContent>;
};
const CardErrorFallback: React.FC<CardErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
  children,
  description,
  cardContentProps = {},
  ...props
}) => {
  const { t } = useTranslation();
  const { className: contentClass, ...contentProps } = cardContentProps;

  return (
    <Card {...props}>
      <CardContent
        className={cn('tw-flex tw-flex-col tw-gap-4 tw-pt-6', contentClass)}
        {...contentProps}
      >
        <div>
          <Alert variant={'destructive'}>
            <XCircle size={20} />
            <If when={!!(error || {}).message}>
              <AlertTitle>{error.message}</AlertTitle>
            </If>
            <AlertDescription>
              {description ||
                t('alerts:error.generic.title', {
                  defaultValue: 'Oops, something went wrong, please try again later.',
                })}
            </AlertDescription>
          </Alert>
        </div>
        <div>
          {children || (
            <Button
              onClick={() => {
                resetErrorBoundary();
              }}
              className={'tw-min-w-36'}
              variant={'outline'}
            >
              {t('actions:try_again', {
                defaultValue: 'Try again',
              })}
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export { CardErrorFallback };
