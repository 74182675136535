import React, { HTMLAttributes, useState } from 'react';
import { useVideoProgressState } from 'src/features/video-player/use-video-progress-store';
import { cn } from 'src/lib/utils';
import { VideoOff } from 'lucide-react';
import { Button } from 'src/components/ui/button/button';
import {
  useVideoPlayerProgress,
  useVideoPlayerV2,
  useVideoPlayToggle,
} from 'src/features/video-player/video-player';
import { VideoPlayerSettingsDropdownMenu } from 'src/features/video-player/video-player-settings-dropdown-menu';

export const VideoPlayerErrorIndicator = React.forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const { loaded } = useVideoProgressState();
  const { seekNextFrame } = useVideoPlayerProgress();
  const { play } = useVideoPlayToggle();
  const { handleSrcChange, src } = useVideoPlayerV2();
  const [hidden, setHidden] = useState(false);

  return (
    <div
      ref={ref}
      className={cn(
        'tw-absolute tw-inset-0 tw-z-20 tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-lg tw-bg-dark/90 tw-p-4 md:tw-gap-4 md:tw-p-6',
        hidden && 'tw-hidden',
        className,
      )}
      {...props}
    >
      {children || (
        <>
          <VideoOff className={'tw-size-6 tw-text-light md:tw-size-16'} />

          <p className={'tw-max-w-xl tw-text-center tw-text-light md:tw-hidden'}>
            {"Video can't be played due to a decoding error."}
          </p>
          <p className={'tw-hidden tw-max-w-xl tw-text-center tw-text-light md:tw-inline'}>
            We encountered an error trying to play this video, likely due to an unsupported format
            or corrupted file. Please try changing <b>playback source</b> if available, switching to{' '}
            <b>different browser</b> or contact support if the issue continues.
          </p>

          <div className={'tw-flex tw-items-center tw-gap-4'}>
            <Button
              onClick={() => {
                setHidden(true);
              }}
              variant={'ghost'}
              size={'sm'}
              className={'tw-mx-auto tw-bg-light tw-px-4 tw-py-2'}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                if (loaded) {
                  seekNextFrame();
                  play();
                } else {
                  handleSrcChange(src, {
                    force: true,
                    playAfterChange: true,
                  });
                }
              }}
              variant={'secondary'}
              size={'sm'}
              className={'tw-mx-auto tw-px-4 tw-py-2'}
            >
              Retry
            </Button>
            <VideoPlayerSettingsDropdownMenu enableCustomTimeFormats={false} />
          </div>
        </>
      )}
    </div>
  );
});
VideoPlayerErrorIndicator.displayName = 'VideoPlayerErrorIndicator';
