import React, { useState } from 'react';
import { Column } from '@tanstack/table-core/src/types';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentProps,
  DropdownMenuProps,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button, ButtonProps } from 'src/components/ui/button/button';
import { ChevronDown } from 'lucide-react';
import { cn } from 'src/lib/utils';

type Props = DropdownMenuProps & {
  menuContentProps?: DropdownMenuContentProps;
  menuTriggerProps?: ButtonProps;
} & {
  columns: Column<any, unknown>[];
  getIsAllColumnsVisible: () => boolean;
  getIsSomeColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (e: unknown) => void;
};

const TableColumnsVisibilityToggle: React.FC<Props> = ({
  menuContentProps,
  menuTriggerProps,
  columns,
  getIsAllColumnsVisible,
  getIsSomeColumnsVisible,
  getToggleAllColumnsVisibilityHandler,
  open,
  onOpenChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(open ?? false);

  return (
    <DropdownMenu
      open={isOpen}
      onOpenChange={(o) => {
        setIsOpen(o);
        onOpenChange?.(o);
      }}
      {...props}
    >
      <DropdownMenuTrigger asChild>
        <Button variant={'outline'} size={'iconEnd'} {...menuTriggerProps}>
          {t('common:columns', { defaultValue: 'Columns' })}
          <ChevronDown
            size={20}
            className={cn('tw-ms-2 tw-transition-transform', isOpen && 'tw-rotate-180')}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent side={'bottom'} align={'end'} {...menuContentProps}>
        <DropdownMenuCheckboxItem
          className={'tw-font-medium'}
          checked={
            getIsAllColumnsVisible() ? true : getIsSomeColumnsVisible() ? 'indeterminate' : false
          }
          onCheckedChange={getToggleAllColumnsVisibilityHandler()}
        >
          {t('actions:toggle_all')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        {columns.map((col, i) => (
          <DropdownMenuCheckboxItem
            key={i}
            checked={col.getIsVisible()}
            onClick={col.getToggleVisibilityHandler()}
            onSelect={(e) => e.preventDefault()}
          >
            {col.columnDef.header as any}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { TableColumnsVisibilityToggle };
