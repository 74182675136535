import { scopedQueries } from 'src/api/queries';
import { ApiQueryObject } from 'src/lib/services/api-query-params';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { InfiniteData } from '@tanstack/query-core/src/types';
import React from 'react';
import { useProfile } from 'src/models/profile';
import { useMakeInfiniteQuery } from 'src/api/queries/useMakeInfiniteQuery';
import {
  commentClient,
  CommentResponse,
  StoreCommentRequest,
} from 'src/lib/services/api/comment-api';

type ApiResponse = Awaited<ReturnType<typeof commentClient.filterRequestComments>>;
type UpdateCommentMutationRequestParameters = Parameters<typeof commentClient.update>;
type ReactCommentMutationRequestParameters = Parameters<typeof commentClient.react>;

const makeQueryKey = (request: string) => ['request', request, 'comments'];

export const useRequestComments = (
  request: string,
  filters: ApiQueryObject & { from_revision?: string },
  queryOptions?: Parameters<typeof useMakeInfiniteQuery<ApiResponse>>[0],
) => {
  const client = useQueryClient();
  // To resolve sender
  const profileData = useProfile({
    enabled: false,
  });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, ...context } =
    useMakeInfiniteQuery<ApiResponse>(
      {
        queryKey: makeQueryKey(request),
        queryFn: ({ signal, ...context }) => {
          return commentClient.filterRequestComments(
            request,
            {
              ...filters,
              page: context.pageParam,
            },
            {
              signal,
            },
          );
        },
        getNextPageParam: (lastPage) => {
          return lastPage.meta.current_page < lastPage.meta.last_page
            ? lastPage.meta.current_page // current_page comes as a number of the next page from backend
            : undefined;
        },
        staleTime: 10 * 1000,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        ...(queryOptions ?? {}),
      },
      filters,
    );

  const loadedCommentsCount = data?.pages.reduce((a, b) => a + b.items.length, 0) ?? 0;

  const addCommentMutation = useMutation({
    mutationFn: ([revision, comment]: [string, StoreCommentRequest]) =>
      commentClient.store(revision, comment),
    onMutate: async ([revision, comment]) => {
      const tempId = Math.random().toString(36).slice(2);

      client.setQueryData<InfiniteData<Partial<ApiResponse>>>(
        [...makeQueryKey(request), scopedQueries.infinite, filters],
        (prev) => {
          if (!prev) {
            return prev;
          }

          const next = { ...prev };
          const firstPage = next.pages[0];
          if (!firstPage.items) {
            return next;
          }

          firstPage.items.unshift({
            ...comment,
            id: tempId,
            revision: revision,
            sender: {
              me: true,
              full_name: profileData.fullName,
              type: profileData.type,
            },
            created_at: new Date().toISOString(),
          } as any);

          return next;
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries<InfiniteData<Partial<ApiResponse>>>([
        ...makeQueryKey(request),
        scopedQueries.infinite,
        filters,
      ]);
    },
  });

  const removeCommentMutation = useMutation({
    mutationFn: (comment: string) => commentClient.remove(comment),
    onMutate: async (comment) => {
      client.setQueryData<InfiniteData<Partial<ApiResponse>>>(
        [...makeQueryKey(request), scopedQueries.infinite, filters],
        (prev) => {
          if (!prev) {
            return prev;
          }

          const next = { ...prev };

          const page = next.pages.find((p) => p.items?.find((c) => c.id === comment));

          if (!page) {
            return prev;
          }

          page.items = page.items!.filter((c) => c.id !== comment);

          return next;
        },
      );
    },
    onSettled: () => {
      client.invalidateQueries([...makeQueryKey(request), scopedQueries.infinite, filters]);
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: (args: UpdateCommentMutationRequestParameters) => commentClient.update(...args),
    onSuccess: () => {
      client.invalidateQueries([...makeQueryKey(request), scopedQueries.infinite, filters]);
    },
  });

  const reactCommentMutation = useMutation({
    mutationFn: (args: ReactCommentMutationRequestParameters) => commentClient.react(...args),
    onSettled: () => {
      client.invalidateQueries([...makeQueryKey(request), scopedQueries.infinite, filters]);
    },
  });

  const comments = data?.pages.reduce((a, b) => a.concat(b.items), [] as CommentResponse[]) ?? [];

  const invalidate = () => client.invalidateQueries(makeQueryKey(request));

  const isLoadingMore = isFetchingNextPage;

  const canLoadMore = hasNextPage;

  const meta = data?.pages[0]?.meta;

  const hasComments = comments.length > 0;

  return {
    context,
    comments,
    hasComments,
    isLoadingMore,
    canLoadMore,
    meta,
    loadedCommentsCount,
    addCommentMutation,
    removeCommentMutation,
    reactCommentMutation,
    updateCommentMutation,
    fetchNextPage,
    invalidate,
    isLoading,
  };
};

const Context = React.createContext<any>(undefined);
export const RequestCommentsQueryContextProvider: React.FC<
  React.PropsWithChildren<{
    request: string;
    filters?: ApiQueryObject & { from_revision?: string };
  }>
> = ({ children, request, filters = {} }) => {
  const context = useRequestComments(request, filters, {
    suspense: true,
    retry: 1,
  });

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useRequestCommentsContext = () => {
  return React.useContext(Context) as ReturnType<typeof useRequestComments>;
};

export const useRequestCommentsReadonlyContext = (
  request: string,
  filters?: ApiQueryObject & { from_revision?: string },
) => {
  return useMakeInfiniteQuery<ApiResponse>(
    {
      queryKey: makeQueryKey(request),
      enabled: false,
    },
    filters,
  );
};
