import React from 'react';
import { ModalProps } from 'reactstrap';
import { FormMediaObject } from 'src/components/Form/S3UploadInput';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';
import { MediaViewer } from './MediaViewer';
import { DownloadCloud, X } from 'lucide-react';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { useTranslation } from 'react-i18next';
import { Badge } from '../ui/badge';

type Props = ModalProps & {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  media: FormMediaObject;
};

const MediaModal: React.FC<Props> = ({ open, onOpenChange, media }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      modal
      open={open}
      onOpenChange={(isOpen) => {
        onOpenChange(isOpen);
      }}
    >
      <DialogContent size={'xl'} className={'tw-gap-4'}>
        <DialogHeader>
          <DialogTitle className={'tw-mr-2 tw-flex tw-items-center tw-gap-3'}>
            <span className={'tw-text-2xl tw-font-bold tw-tracking-tight'}>{media.file_name}</span>
            <Badge variant={'info'}>{media.mime_type}</Badge>
          </DialogTitle>
          <div className={'tw-flex tw-gap-2'}>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipContent>{t('Download')}</TooltipContent>
                <TooltipTrigger asChild>
                  <Button asChild size={'icon'} variant={'ghost'}>
                    <a
                      href={media?.index_url}
                      className={cn('py-2', !media?.index_url && 'disabled')}
                    >
                      <DownloadCloud size={20} />
                    </a>
                  </Button>
                </TooltipTrigger>
              </Tooltip>
            </TooltipProvider>
            <DialogClose asChild>
              <Button type={'button'} variant={'ghost'} size={'icon'}>
                <X size={20} />
              </Button>
            </DialogClose>
          </div>
        </DialogHeader>
        <div
          style={{
            maxHeight: window.innerHeight / 1.5,
          }}
        >
          <MediaViewer
            style={{
              objectFit: 'scale-down',
            }}
            className={'rounded-3 mt-auto mb-auto'}
            media={media}
            playable={true}
            renderOriginal={true}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { MediaModal };
