import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { Button } from 'src/components/ui/button/button';
import { Cable, Clock, Gauge, LucideIcon, Settings } from 'lucide-react';
import {
  useVideoPlaybackRate,
  useVideoPlayerV2,
  useVideoTimeFormat,
} from 'src/features/video-player/video-player';
import { makePlaybackRates, makeTimeFormats } from 'src/features/video-player/video-player-utils';
import { cn } from 'src/lib/utils';

export const SettingsDropdownMenuSubTrigger: React.FC<
  React.ComponentProps<typeof DropdownMenuSubTrigger> & {
    icon: LucideIcon;
  }
> = ({ children, className, icon: Icon, ...props }) => {
  return (
    <DropdownMenuSubTrigger className={cn('tw-flex tw-items-center', className)} {...props}>
      <Icon className={'tw-me-2 tw-size-5 tw-text-text'} />
      <span className={'tw-flex tw-grow tw-justify-between'}>{children}</span>
    </DropdownMenuSubTrigger>
  );
};

type VideoPlayerSettingsDropdownMenuProps = React.PropsWithChildren<{
  enableCustomTimeFormats?: boolean;
  onTimeFormatChange?: (format: string) => void;
}>;

export const VideoPlayerSettingsDropdownMenu: React.FC<VideoPlayerSettingsDropdownMenuProps> = ({
  children,
  enableCustomTimeFormats = false,
  onTimeFormatChange,
}) => {
  const playerContext = useVideoPlayerV2();
  const [rate, setRate] = useVideoPlaybackRate();
  const [timeFormat, setTimeFormat] = useVideoTimeFormat();

  // Playback rate
  const playbackRates = makePlaybackRates();
  const rateOptions = playbackRates.options;
  const selectedRateOption = playbackRates.find(rate);

  // Time format
  const timeFormats = makeTimeFormats();
  const timeFormatOptions = enableCustomTimeFormats ? timeFormats.options : [timeFormats.default];
  const selectedTimeFormatOption = timeFormats.find(timeFormat);

  // Source
  const selectedSourceOption = playerContext.srcOptions.find((o) => o.value === playerContext.src);

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger className={'tw-ms-auto'} asChild>
        <Button
          variant={'unset'}
          size={'icon'}
          className={'tw-relative !tw-ring-0 focus-visible:!tw-ring-offset-0'}
        >
          {playerContext.isError && (
            <div className={'tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center'}>
              <span className={'tw-size-6 tw-animate-ping tw-rounded-full tw-bg-blue-300'} />
            </div>
          )}
          <Settings className={'tw-size-5 tw-text-gray-100 hover:tw-text-gray-50'} />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className={'tw-min-w-64'}
        side={'top'}
        sideOffset={10}
        align={'end'}
        portalProps={{
          container: playerContext.overlayRef?.current,
        }}
      >
        <DropdownMenuLabel>Settings</DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuSub>
          <SettingsDropdownMenuSubTrigger icon={Gauge}>
            Playback speed
            <small className={'tw-ms-2'}>{selectedRateOption?.label || rate}</small>
          </SettingsDropdownMenuSubTrigger>

          <DropdownMenuPortal container={playerContext.overlayRef?.current}>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup value={`${rate}`} onValueChange={setRate}>
                {rateOptions.map(({ value, label }) => (
                  <DropdownMenuRadioItem key={value} value={`${value}`}>
                    {label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <SettingsDropdownMenuSubTrigger icon={Clock}>
            Time display
            <small className={'tw-ms-2'}>{selectedTimeFormatOption?.label || timeFormat}</small>
          </SettingsDropdownMenuSubTrigger>

          <DropdownMenuPortal container={playerContext.overlayRef?.current}>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup
                value={timeFormat}
                onValueChange={(f) => {
                  setTimeFormat(f);
                  onTimeFormatChange?.(f);
                }}
              >
                {timeFormatOptions.map(({ value, label }) => (
                  <DropdownMenuRadioItem key={value} value={value}>
                    {label}
                  </DropdownMenuRadioItem>
                ))}
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>

        {playerContext.srcOptions.length ? (
          <DropdownMenuGroup>
            <DropdownMenuLabel>Advanced</DropdownMenuLabel>
            <DropdownMenuSub>
              <SettingsDropdownMenuSubTrigger icon={Cable}>
                Playback source
                <small className={'tw-ms-2'}>{selectedSourceOption?.label ?? 'N\\A'}</small>
              </SettingsDropdownMenuSubTrigger>

              <DropdownMenuPortal container={playerContext.overlayRef.current}>
                <DropdownMenuSubContent>
                  <DropdownMenuRadioGroup
                    value={playerContext.src}
                    onValueChange={playerContext.handleSrcChange}
                  >
                    {playerContext.srcOptions.map(({ value, label }) => (
                      <DropdownMenuRadioItem key={value} value={value}>
                        {label}
                      </DropdownMenuRadioItem>
                    ))}
                  </DropdownMenuRadioGroup>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
        ) : null}

        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
