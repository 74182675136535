import React, { ImgHTMLAttributes } from 'react';
import NotFoundImage from 'src/assets/images/404-error.png';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button/button';

const NotFoundFallbackHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} {...props} className={cn('tw-flex tw-flex-col tw-gap-2', className)} />
));

const NotFoundFallbackTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h1 ref={ref} {...props} className={cn('tw-text-title', className)} />
));

const NotFoundFallbackDescription = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ ...props }, ref) => <h4 ref={ref} {...props} />);

const NotFoundFallbackImage = React.forwardRef<HTMLImageElement, ImgHTMLAttributes<any>>(
  ({ className, src, ...props }, ref) => (
    <img ref={ref} src={src ?? NotFoundImage} {...props} className={className} />
  ),
);

const NotFoundFallbackAction = Button;

const NotFoundFallback: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div {...props} className={cn('tw-flex tw-flex-col tw-gap-4', className)}>
      {children}
    </div>
  );
};

export {
  NotFoundFallbackHeader,
  NotFoundFallbackTitle,
  NotFoundFallbackDescription,
  NotFoundFallbackImage,
  NotFoundFallbackAction,
  NotFoundFallback,
};
