import {
  RequestFlow,
  requestFlow,
  requestStatus,
  TotalLengthResponse,
} from 'src/lib/services/api/request-api';
import { generatePath } from 'react-router-dom';
import { appRoutes } from 'src/routes';
import { TFunction } from 'i18next';

const totalLengthOtherValue = '-other-';

const canEditRequest: (request: { revisions_count?: number; status?: string | any }) => boolean = ({
  status,
  revisions_count,
}) => !revisions_count && [requestStatus.draft, requestStatus.queued].includes(status);

const canViewOutcome: (request: { revisions_count?: number }) => boolean = (request) => {
  return !!request.revisions_count;
};

const getRequestNavigationRoute = (requestId: string, flow: RequestFlow) => {
  switch (flow) {
    case requestFlow.outcome:
      return generatePath(appRoutes.requestRevisions, { requestId });
    case requestFlow.multiOutcome:
      return generatePath(appRoutes.request, { requestId });
    case requestFlow.repurpose:
      return generatePath(appRoutes.request, { requestId });
    default:
      return generatePath(appRoutes.requestRevisions, { requestId });
  }
};

const totalLengthToString = (totalLength?: TotalLengthResponse) => {
  if (!totalLength || !totalLength.value || !totalLength.unit) {
    return undefined;
  }

  return `up to ${totalLength.value} ${totalLength.unit}`;
};

const requestStatusTranslationsMap = (status: string) => {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'queued':
      return 'Queued';
    case 'currently_editing':
      return 'Editing';
    case 'delivered':
      return 'Ready for review';
    case 'complete':
      return 'Completed';
    case 'archived':
      return 'Archived';
    case 'all':
      return 'All';
    default:
      return 'Unknown';
  }
};

export const formatDueDate: (t: TFunction, dueDate: string | Date) => string = (t, dueDate) => {
  return t('Up to {{val, datetime}}', {
    val: new Date(dueDate),
    formatParams: {
      val: { year: 'numeric', month: 'short', day: 'numeric' },
    },
  });
};

export const canDeleteRequest: (request: {
  revisions_count?: number;
  revisions?: any[];
  status?: string | any;
}) => boolean = (request) => {
  return (
    !request.revisions_count &&
    !request.revisions?.length &&
    [requestStatus.queued, requestStatus.draft].includes(request.status)
  );
};

export const canViewRequest: (request: {
  revisions_count?: number;
  revisions?: any[];
  flow?: string | any;
  status?: string | any;
}) => boolean = (request) => {
  return (
    !!request.revisions_count ||
    !!request.revisions?.length ||
    ([requestFlow.multiOutcome, requestFlow.repurpose].includes(request.flow) &&
      ![requestStatus.draft, requestStatus.queued].includes(request.status))
  );
};

export {
  totalLengthOtherValue,
  canEditRequest,
  canViewOutcome,
  getRequestNavigationRoute,
  totalLengthToString,
  requestStatusTranslationsMap,
};
