import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useOpenRequestModal } from 'src/features/requests/request-form/use-open-request-modal';
import { cn } from 'src/lib/utils';
import { Link } from 'src/components/ui/link';
import { appRoutes } from 'src/routes';
import { If } from 'src/components/If';
import { Button } from 'src/components/ui/button/button';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from 'src/components/ui/breadcrumb';
import { ChevronLeft, Video } from 'lucide-react';
import { PageHeading, PageHeadingTitle } from 'src/components/ui/page-heading';

const RequestMultiOutcomePageContentTop = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id, title, canEdit } = useDecoratedRequestContext();
  const { openRequestModal } = useOpenRequestModal();

  return (
    <div
      ref={ref}
      className={cn('tw-flex tw-flex-wrap tw-items-center tw-gap-4', className)}
      {...props}
    >
      {children || (
        <>
          <PageHeading>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Button
                    variant={'unset'}
                    size={'iconXs'}
                    onClick={() => {
                      if (location.key !== 'default') {
                        history.back();
                      } else {
                        navigate(appRoutes.dashboard);
                      }
                    }}
                  >
                    <ChevronLeft className={'tw-size-4'} />
                  </Button>
                  <BreadcrumbLink asChild>
                    <Link to={appRoutes.dashboard}>
                      {t('common:dashboard', {
                        defaultValue: 'Dashboard',
                      })}
                    </Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to={appRoutes.requestsList}>
                      {t('common:requests', {
                        defaultValue: 'Requests',
                      })}
                    </Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbPage>
                  {t('common:request', {
                    defaultValue: 'Request',
                  })}
                </BreadcrumbPage>
              </BreadcrumbList>
            </Breadcrumb>
            <PageHeadingTitle className={'tw-flex-nowrap'}>
              <div>
                <Video className={'tw-size-6'} />
              </div>
              <span className={'tw-line-clamp-1'}>
                {title || t('common:request', { defaultValue: 'Request' })}
              </span>
            </PageHeadingTitle>
          </PageHeading>

          <div className={'tw-ms-auto tw-flex tw-gap-2'}>
            <If when={canEdit}>
              <Button
                type={'button'}
                variant={'brand'}
                size={'responsive'}
                onClick={async () => {
                  await openRequestModal(id);
                }}
              >
                {t('pages/space:forms.request.edit')}
              </Button>
            </If>
          </div>
        </>
      )}
    </div>
  );
});
RequestMultiOutcomePageContentTop.displayName = 'RequestDefaultPageContentTop';

export { RequestMultiOutcomePageContentTop };
