import React, { useEffect, useRef, useState } from 'react';
import { If } from 'src/components/If';
import { useTranslation } from 'react-i18next';
import { useRequestCommentsContext } from 'src/features/comments/use-request-comments';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { ArrowRight, Loader2 } from 'lucide-react';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { arr, cn } from 'src/lib/utils';
import { Badge } from 'src/components/ui/badge';
import { Card } from 'src/components/ui/card';
import { Button } from 'src/components/ui/button/button';
import { RevisionChatInputForm } from 'src/pages/RevisionsPage/revisions-content-main/revision-chat-input-form';
import { RevisionChatComment } from 'src/pages/RevisionsPage/revisions-content-main/revision-chat-comment';
import { RevisionChatCommentReplySheet } from 'src/pages/RevisionsPage/revisions-content-main/revision-chat-comment-reply-sheet';

type RevisionChatContentProps = React.ComponentProps<typeof Card>;

export const RevisionChatSection: React.FC<RevisionChatContentProps> = ({
  className,
  children,
  ...props
}) => (
  <Card
    {...props}
    className={cn('tw-relative tw-flex tw-min-w-0 tw-flex-col tw-gap-3', className)}
    variant={'noShadow'}
  >
    {children}
  </Card>
);

const RevisionChatContent: React.FC = () => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [currentCommentEditing, setCurrentCommentEditing] = useState<string>('');

  const request = useDecoratedRequestContext();
  const { revisions, selectedRevision, selectLatest } = useRequestRevisionsStore();

  const {
    comments,
    isLoadingMore,
    canLoadMore,
    fetchNextPage,
    hasComments,
    addCommentMutation,
    removeCommentMutation,
    reactCommentMutation,
    invalidate,
  } = useRequestCommentsContext();

  useEffect(() => {
    const timeout = setTimeout(() => scrollToBottom(), 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const scrollToBottom = () => {
    if (!scrollRef.current || !scrollRef.current?.scrollHeight) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  };

  // TODO add here when revision comment thread has comment thread ID in url
  // sort through comments and set active comment if exists

  const groups = arr.unique(comments.map((c) => c.revision));

  const groupedByRevision = groups.reduce(
    (result, revision) => {
      if (!result[revision]) {
        result[revision] = [];
      }

      result[revision] = comments.filter((c) => c.revision === revision);

      return result;
    },
    {} as Record<string, typeof comments>,
  );

  return (
    <>
      <ScrollArea
        className={'!tw-m-0 tw-max-h-[60vh] tw-grow tw-px-4 tw-pt-4'}
        viewportProps={{
          className: '[&>div]:!tw-block',
        }}
        scrollRef={scrollRef}
        onScrollUp={(ref) => {
          if (!ref.scrollTop && canLoadMore && !isLoadingMore) {
            fetchNextPage();
          }
        }}
      >
        <If when={isLoadingMore}>
          <div className={'tw-flex tw-w-full tw-pb-4'}>
            <Loader2 className={'tw-mx-auto tw-size-8 tw-animate-spin tw-text-secondary'} />
          </div>
        </If>

        <If when={!hasComments && !isLoadingMore && !!selectedRevision}>
          <div className={'tw-text-center tw-text-text-strong'}>
            {t('This revision has no comments yet')}
          </div>
        </If>

        <If when={hasComments}>
          <div className={'tw-flex tw-flex-col-reverse tw-gap-4'}>
            {groups.map((revisionId) => (
              <React.Fragment key={revisionId}>
                <div className={'tw-flex tw-flex-col-reverse tw-gap-4'}>
                  <If
                    when={!!groupedByRevision[revisionId]?.length}
                    else={
                      <div className={'tw-text-center  tw-text-text-strong'}>
                        {t('This revision has no comments yet')}
                      </div>
                    }
                  >
                    {groupedByRevision[revisionId]?.map((comment) => (
                      <RevisionChatComment
                        key={comment.id}
                        comment={comment}
                        deleteMutation={removeCommentMutation}
                        reactMutation={(commentId: string, reaction: string) =>
                          reactCommentMutation.mutate([commentId, { reaction }], {
                            onSuccess: () => {
                              invalidate();
                            },
                          })
                        }
                        currentCommentEditing={currentCommentEditing}
                        setCurrentCommentEditing={setCurrentCommentEditing}
                      />
                    ))}
                  </If>
                </div>

                <Badge
                  variant={'brandExtraLight'}
                  className={
                    'tw-flex tw-flex-col tw-gap-1 tw-self-center tw-px-4 tw-pb-1.5 tw-pt-2'
                  }
                >
                  <div className={'tw-font-semibold tw-leading-none'}>
                    {revisions.find((r) => r.id === revisionId)?.title ?? t('Version')}
                  </div>
                  <div className={'tw-font-normal tw-leading-none'}>
                    {t('{{val, datetime}}', {
                      val: new Date(
                        revisions.find((r) => r.id === revisionId)?.raw.created_at ?? Date.now(),
                      ),
                    })}
                  </div>
                </Badge>
              </React.Fragment>
            ))}
          </div>
        </If>
      </ScrollArea>

      <div className={cn('tw-relative !tw-mt-auto tw-p-4')}>
        <If
          when={request.isCompleted}
          else={
            <If when={!selectedRevision?.isLatest}>
              <div
                className={
                  'tw-absolute tw-inset-0 tw-z-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-bg-brand-200/90 tw-p-4'
                }
              >
                <div className={'tw-text-center tw-font-medium tw-text-text-strong'}>
                  {t('Commenting is unavailable for completed revisions!')}
                </div>
                <Button size={'iconEndSmResponsive'} variant={'brand'} onClick={selectLatest}>
                  <span>{t('Go to current version')}</span>
                  <ArrowRight size={16} />
                </Button>
              </div>
            </If>
          }
        >
          <div
            className={
              'tw-absolute tw-inset-0 tw-z-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-bg-brand-200/90 tw-p-4'
            }
          >
            <div className={'tw-text-center tw-font-medium tw-text-text-strong'}>
              {t('Commenting is unavailable for completed revisions!')}
            </div>
          </div>
        </If>

        <RevisionChatInputForm
          commentMutation={addCommentMutation}
          onSubmit={() => {
            invalidate();
            setTimeout(() => scrollToBottom(), 1);
          }}
        />
      </div>

      <RevisionChatCommentReplySheet />
    </>
  );
};

export { RevisionChatContent };
