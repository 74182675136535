import { requestClient, requestStatus } from 'src/lib/services/api/request-api';
import { t } from 'i18next';
import { decorateMedia } from 'src/features/media';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { create } from 'zustand';

type RevisionsCollection = Awaited<ReturnType<typeof requestClient.get>>['revisions'];
type RevisionItem = RevisionsCollection[number];

type DecoratedRevision = {
  raw: RevisionItem;
  id: string;
  isVideoRevision: boolean;
  isFinalFiles: boolean;
  isFirstContact: boolean;
  isLatest: boolean;
  isOldest: boolean;
  /** @deprecated */
  hasAirtableProps: boolean;
  hasMedia: boolean;
  canDelete: boolean;
  i: number;
  title: string;
  media: ReturnType<typeof decorateMedia>[];
};
type DecoratedRevisionMediaItem = DecoratedRevision['media'][number];
type DecoratedRevisionsCollection = DecoratedRevision[];

export const decorateRevisions = (revisions: RevisionsCollection): DecoratedRevisionsCollection => {
  // eslint-disable-next-line
  let version = 0;
  const n = revisions.length;

  return revisions.map((revision, i) => {
    const isVideoRevision = revision.type === 'revision',
      isFinalFiles = revision.type === 'final_files',
      isFirstContact = revision.type === 'first_contact',
      title = isVideoRevision
        ? `${t('models/revision:version.singular', {
            defaultValue: 'Version',
          })} ${++version}`
        : isFinalFiles
          ? t('models/revision:type.final_files', {
              defaultValue: 'Final Files',
            })
          : t('models/revision:type.first_contact', {
              defaultValue: 'First Contact',
            });

    return {
      raw: revision,
      id: revision.id,
      isVideoRevision,
      isFinalFiles,
      isFirstContact,
      canDelete: !isFirstContact,
      isLatest: n === i + 1,
      isOldest: i === 0,
      hasAirtableProps: !!revision.airtable?.revision_url,
      hasMedia: !!revision?.media?.length,
      i,
      title,
      media: revision.media?.map((m) => decorateMedia(m)) ?? [],
    };
  });
};

type SelectedRevisionStoreObject = {
  selectedRevision?: string;
  selectedRevisionMedia?: string;
  setSelectedRevision: (selectedRevision?: string) => void;
  setSelectedRevisionMedia: (selectedRevisionMedia?: string) => void;
  set: (state: Partial<SelectedRevisionStoreObject>) => void;
  reset: () => void;
};

export const useSelectedRevisionStore = create<SelectedRevisionStoreObject>((set) => {
  return {
    selectedRevision: undefined,
    selectedRevisionMedia: undefined,

    setSelectedRevision: (selectedRevision) => set({ selectedRevision }),
    setSelectedRevisionMedia: (selectedRevisionMedia) => set({ selectedRevisionMedia }),
    reset: () => set({ selectedRevision: undefined, selectedRevisionMedia: undefined }),
    set,
  };
});

type RevisionControlInterface = {
  revisions: DecoratedRevisionsCollection;
  isEmpty: boolean;
  canSelectNext: boolean;
  canSelectPrevious: boolean;
  canComment: boolean;
  selectedRevision?: DecoratedRevision;
  selectedRevisionMedia?: DecoratedRevisionMediaItem;
  isRevisionSelected: (revision: DecoratedRevision | string) => boolean;
  isRevisionMediaSelected: (media: DecoratedRevisionMediaItem | string) => boolean;
  select: (revision: DecoratedRevision | string) => void;
  selectNext: () => void;
  selectPrevious: () => void;
  selectOldest: () => void;
  selectLatest: () => void;
  setSelectedRevision: (revision?: string) => void;
  setSelectedRevisionMedia: (selectedRevisionMedia?: string) => void;
  initialize: (bag?: DecoratedRevisionsCollection) => void;
  reset: () => void;
};

const getPreferredRevision = (
  revisions: DecoratedRevisionsCollection,
): DecoratedRevision | undefined => revisions.find((r) => r.isLatest);

const getPreferredMedia = (revision?: DecoratedRevision): DecoratedRevisionMediaItem | undefined =>
  revision?.media.find((m) => m.isVideo) ?? revision?.media?.[0];

// Can only be used under request query context since it's all one big bag of things
// Really need to split this up
const useRequestRevisionsStore = (): RevisionControlInterface => {
  const {
    setSelectedRevision,
    selectedRevision: selectedRevisionId,
    selectedRevisionMedia: selectedRevisionMediaId,
    setSelectedRevisionMedia,
    reset,
    set,
  } = useSelectedRevisionStore();

  const request = useDecoratedRequestContext();

  const decoratedRevisions = decorateRevisions(request.revisions);

  const selectPreferredMedia = (revision?: DecoratedRevision) => {
    // Passed or latest
    const resolvedRevision = revision ?? decoratedRevisions.find((r) => r.isLatest);
    setSelectedRevisionMedia(getPreferredMedia(resolvedRevision)?.id);
  };

  const initialize = (bag?: DecoratedRevisionsCollection) => {
    const preferredRevision = getPreferredRevision(bag ?? decoratedRevisions);
    const preferredMedia = getPreferredMedia(preferredRevision);

    set({
      selectedRevision: preferredRevision?.id,
      selectedRevisionMedia: preferredMedia?.id,
    });
  };

  const requestRevisions = decoratedRevisions,
    revisionsCount = requestRevisions.length,
    selectedRevision = requestRevisions.find((r) => r.id === selectedRevisionId),
    selectedRevisionMedia = selectedRevision?.media.find((m) => m.id === selectedRevisionMediaId),
    isEmpty = !revisionsCount,
    canSelectNext = !isEmpty && !selectedRevision?.isLatest,
    canSelectPrevious = !isEmpty && !selectedRevision?.isOldest,
    canComment = !!(selectedRevision?.isLatest && !request.isOfStatus(requestStatus.complete));

  const isRevisionSelected = (revision: DecoratedRevision | string): boolean =>
    typeof revision === 'string'
      ? selectedRevision?.id === revision
      : selectedRevision?.id === revision.id;
  const isRevisionMediaSelected = (media: DecoratedRevisionMediaItem | string): boolean =>
    typeof media === 'string'
      ? selectedRevisionMedia?.id === media
      : selectedRevisionMedia?.id === media?.id;

  const select = (revision: string | DecoratedRevision): void => {
    const next =
      typeof revision === 'string'
        ? decoratedRevisions.find((r) => r.raw.id === revision)
        : revision;

    setSelectedRevision(next?.id);
    selectPreferredMedia(next);
  };

  const selectNext = (): void => {
    const index = decoratedRevisions.findIndex((r) => r.raw.id === selectedRevision?.raw.id);
    if (index < 0) {
      return;
    }

    const nextIndex = index + 1;
    const nextRevision = decoratedRevisions?.[nextIndex];

    select(nextRevision);
  };

  const selectPrevious = (): void => {
    const index = decoratedRevisions.findIndex((r) => r.raw.id === selectedRevision?.raw.id);
    const prevIndex = index - 1;
    if (index < 0) {
      return;
    }

    const prevRevision = decoratedRevisions?.[prevIndex];

    select(prevRevision);
  };

  const selectLatest = (): void => {
    select(decoratedRevisions?.[decoratedRevisions?.length - 1]);
  };

  const selectOldest = (): void => {
    select(decoratedRevisions?.[0]);
  };

  return {
    revisions: decoratedRevisions,
    isEmpty,
    canSelectNext,
    canSelectPrevious,
    canComment,
    selectedRevision,
    selectedRevisionMedia,
    isRevisionSelected,
    isRevisionMediaSelected,
    select,
    selectNext,
    selectPrevious,
    selectOldest,
    selectLatest,
    setSelectedRevision,
    setSelectedRevisionMedia,
    initialize,
    reset,
  };
};

export { useRequestRevisionsStore };
