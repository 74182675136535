import React, { useMemo } from 'react';
import { X } from 'lucide-react';
import { Button } from 'src/components/ui/button/button';
import { If } from 'src/components/If';
import { Progress } from 'src/components/ui/progress';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';
import { HookFormMediaObject } from 'src/components/Form/S3UploadInput';
import { video } from 'src/features/media';
import { getExtension, isImageMime, isVideoMime } from 'src/helpers/file';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { FileIcon } from '../FileIcon';

type Props = {
  media: HookFormMediaObject;
  onDelete: () => void;
  showTitle?: boolean;
};

const MinimalMediaFormCard: React.FC<Props> = ({ media, showTitle, onDelete }) => {
  const { uploadDetails, uploadStatus } = useMediaUploadStore(
    useShallow((state) => ({
      uploadDetails: state.details[media.id],
      uploadStatus: state.status[media.id],
    })),
  );

  const { t } = useTranslation();

  const { shouldRenderVideoTag, shouldRenderImageTag, isUnknown } = useMemo(() => {
    const extension = getExtension(media.file_name);
    const isVideo = isVideoMime(media.mime_type) || video.hasExtension(extension);
    const isImage = isImageMime(media.mime_type);

    const shouldRenderVideoTag = isVideo && media.filePreviewUrl?.startsWith('blob:');
    const shouldRenderImageTag =
      (isImage && !!media.filePreviewUrl) ||
      (isVideo && !shouldRenderVideoTag && !!media.filePreviewUrl);

    const isUnknown = !isVideo && !isImage;

    return { shouldRenderVideoTag, shouldRenderImageTag, isUnknown };
  }, [media]);

  return (
    <div
      className={cn('tw-relative tw-flex tw-w-20 tw-min-w-20 tw-max-w-full', {
        'validation-error tw-border tw-border-orange': uploadStatus?.failed,
      })}
    >
      <div
        className={cn('tw-w-full tw-rounded-lg tw-p-0', {
          'tw-bg-orange-100': uploadStatus?.failed,
        })}
      >
        <div className={cn('tw-relative tw-h-12')}>
          <If when={shouldRenderVideoTag}>
            <video
              src={media.filePreviewUrl}
              controls={false}
              className={'tw-h-full tw-w-full tw-rounded-md tw-object-cover'}
            />
          </If>
          <If when={shouldRenderImageTag}>
            <img
              src={media.filePreviewUrl}
              alt={'media'}
              className={'tw-h-full tw-w-full tw-rounded-md tw-object-cover'}
            />
          </If>
          <If when={isUnknown}>
            <FileIcon
              mimeType={media?.mime_type ?? ''}
              className={cn('tw-mx-auto tw-h-12 tw-w-12')}
            />
          </If>
        </div>

        <If when={uploadStatus?.isUploading}>
          <Progress
            value={uploadDetails?.progress}
            className={'tw-mt-0.5 tw-h-1 tw-rounded-full'}
          />
        </If>

        <div
          className={cn('tw-flex tw-flex-col tw-justify-between tw-p-2 tw-text-text', {
            'tw-text-orange-900': uploadStatus?.failed,
          })}
        >
          {showTitle && (
            <div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div
                      className={
                        'tw-max-w-20 tw-truncate tw-break-all tw-text-sm tw-font-bold tw-leading-normal'
                      }
                    >
                      {media.file_name}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{media.file_name}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
          {onDelete && (
            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2'}>
              <div>
                <If when={uploadStatus?.failed}>
                  <span className={'tw-text-sm tw-font-bold tw-text-orange-900'}>
                    {t('Upload failed')}
                  </span>
                </If>
              </div>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      className={'tw-absolute -tw-right-1 -tw-top-1 tw-z-10 tw-bg-white'}
                      type={'button'}
                      variant={'destructiveOutline'}
                      size={'iconXs'}
                      onClick={onDelete}
                    >
                      <X size={14} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('Delete')}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { MinimalMediaFormCard };
