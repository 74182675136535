import { useTranslation } from 'react-i18next';
import { useFormatRelativeTime } from './useFormatRelativeTime';
import { DateTimeFormatOptions } from './i18n.types';

const resolveDate = (date: Date | string | number): Date =>
  date instanceof Date ? date : new Date(date);

const useFormatter = () => {
  const { t } = useTranslation();
  const formatRelativeTime = useFormatRelativeTime();

  return {
    formatDate: (date: Date | string | number, format?: DateTimeFormatOptions): string => {
      return t('{{val, datetime}}', {
        val: resolveDate(date),
        formatParams: {
          val: format,
        },
      });
    },
    formatDateTime: (date: Date | string | number, format?: DateTimeFormatOptions): string => {
      return t(`{{val, datetime}}`, {
        val: resolveDate(date),
        formatParams: {
          val: format ?? {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
          },
        },
      });
    },
    formatRelativeTime,
    formatCurrency: (amount: number, currency = 'USD'): string => {
      return t(`{{val, currency(${currency})}}`, { val: amount });
    },
    formatCurrencyTruncated: (amount: number, currency = 'USD'): string => {
      return t(`{{val, currency(${currency})}}`, { val: amount, maximumFractionDigits: 0 });
    },
    formatPercent: (amount: number): string => `${amount}%`,
  };
};

export { useFormatter };
