import React from 'react';
import { SortParam } from 'src/lib/services/api-query-params';
import { GetSortOrderOptionsFn, SortItem } from 'src/lib/services/api-query-params/use-sorts';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'src/lib/state-utils';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button/button';
import { ChevronDown, Trash2 } from 'lucide-react';
import ReactSelectInput from 'src/components/ReactSelectInput/ReactSelectInput';
import {
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
  CommandListProps,
} from 'src/components/ui/command';
import { ScrollArea } from 'src/components/ui/scroll-area';

const SortMenuItem: React.FC<{
  item: SortParam;
  allSorts: SortItem[];
  sortList: SortItem[];
  updateSort: (id: string, value: SortParam) => void;
  removeSort: (sort: SortParam) => void;
  getSortOrderOptions: GetSortOrderOptionsFn;
  portalSelectMenu?: boolean;
}> = ({
  item,
  allSorts,
  sortList,
  updateSort,
  removeSort,
  getSortOrderOptions,
  portalSelectMenu,
}) => {
  const { t } = useTranslation();
  const [updateSortMenuOpen, setUpdateSortMenuOpen] = useToggle();

  const sortItem = allSorts.find((s) => s.sort.id === item.id)!;

  // if undefined sort is applied, we can't render it.
  if (!sortItem) {
    return null;
  }

  const orderOptions = getSortOrderOptions(sortItem.format);

  return (
    <div
      className={
        'tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-rounded-lg tw-bg-neutral-50 tw-p-2 sm:tw-flex-nowrap'
      }
    >
      <Popover
        modal={true}
        open={updateSortMenuOpen}
        onOpenChange={(open) => setUpdateSortMenuOpen(open)}
      >
        <PopoverTrigger asChild>
          <Button
            size={'unset'}
            variant={'outline'}
            className={
              'tw-h-11 tw-w-full tw-grow tw-justify-between tw-gap-2 tw-bg-background tw-p-3 tw-font-normal'
            }
          >
            {sortItem.label}
            <ChevronDown size={16} />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align={'start'}
          className={
            'tw-max-h-[var(--radix-popper-available-height)] tw-w-[var(--radix-popper-anchor-width)] tw-overflow-y-auto'
          }
        >
          <SortCommandList
            sortList={sortList}
            onSelect={(value) => {
              // update sort value ID, keep same order direction
              updateSort(item.id, {
                ...value,
                desc: item.desc,
              });
              // close select sort popover
              setUpdateSortMenuOpen(false);
            }}
            commandListProps={{ className: 'tw-max-h-none' }}
          />
        </PopoverContent>
      </Popover>
      <div className={'tw-w-full tw-grow'}>
        <ReactSelectInput
          menuPortalTarget={portalSelectMenu ? document.body : null}
          isClearable={false}
          value={orderOptions.find((s) => s.value === item.desc)}
          options={orderOptions}
          className={'[&>.select-control]:tw-h-11'}
          onChange={(option: any) => {
            if (!option || option.value === null) {
              return;
            }

            // update sort direction
            updateSort(item.id, {
              id: item.id,
              desc: option.value,
            });
          }}
        />
      </div>
      <div className={'tw-flex tw-grow tw-justify-end'}>
        <Button variant={'destructiveLight'} size={'iconSm'} onClick={() => removeSort(item)}>
          <Trash2 className={'tw-size-4'} />
          <span className="tw-sr-only">{t('actions:delete')}</span>
        </Button>
      </div>
    </div>
  );
};

const SortCommandList: React.FC<{
  sortList: SortItem[];
  onSelect: (value: SortParam) => void;
  commandListProps?: CommandListProps;
}> = ({ sortList, onSelect, commandListProps }) => {
  const { t } = useTranslation();

  return (
    <Command>
      <CommandEmpty>{t('common:no_fields_found', 'No fields found')}</CommandEmpty>
      <ScrollArea>
        <div className={'tw-max-h-[300px]'}>
          <CommandList className={'tw-overflow-visible'} {...commandListProps}>
            {sortList?.map((sortItem, index) => (
              <CommandItem
                key={index}
                value={sortItem.sort.id}
                keywords={sortItem.keywords}
                onSelect={() => onSelect(sortItem.sort)}
              >
                <span>{sortItem.label}</span>
              </CommandItem>
            ))}
          </CommandList>
        </div>
      </ScrollArea>
    </Command>
  );
};

export { SortMenuItem, SortCommandList };
