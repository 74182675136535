import React from 'react';
import { CollapsibleCard } from 'src/components/ui/collapsible-card';
import { useTranslation } from 'react-i18next';
import { useDecoratedRequestOutcomeContext } from 'src/features/requests/request-outcome/use-decorated-request-outcome-context';
import { cn } from 'src/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Button } from 'src/components/ui/button/button';
import { ArchiveRestore, ArchiveX, Loader2 } from 'lucide-react';
import { RequestStatusBadge } from 'src/features/requests/request-status-badge';
import { RequestOutcomePriority } from 'src/features/requests/request-outcome/request-outcome-priority';
import { useConfirm } from 'src/components/ConfirmModal';
import { requestClient, requestOutcomeStatus } from 'src/lib/services/api/request-api';
import { useMutation } from '@tanstack/react-query';
import { If } from 'src/components/If';
import {
  useRequestArchivedOutcomesQueryMutator,
  useRequestPendingOutcomesQueryMutator,
} from 'src/features/requests/request-outcome/use-request-outcomes-query-mutation-decorator';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import {
  CollapsibleInnerCard,
  CollapsibleInnerCardContent,
  CollapsibleInnerContent,
} from 'src/components/ui/collapsible-inner-card';
import { requestStatusTranslationsMap } from 'src/features/requests/request.helper';

const RequestPendingOutcomeCard: React.FC<
  React.ComponentProps<typeof CollapsibleCard> & {
    cardTop?: React.ReactNode;
  }
> = ({ children, className, open, cardTop, ...props }) => {
  const { t } = useTranslation();
  const outcome = useDecoratedRequestOutcomeContext();
  const request = useDecoratedRequestContext();
  const { confirm } = useConfirm();

  const pendingOutcomesQueryMutator = useRequestPendingOutcomesQueryMutator(outcome.raw.parent_id);
  const archivedOutcomesQueryMutator = useRequestArchivedOutcomesQueryMutator(
    outcome.raw.parent_id,
  );

  const archiveOutcomeMutation = useMutation((id: string) => {
    return requestClient.archive(id);
  });
  const restoreOutcomeMutation = useMutation((id: string) => {
    return requestClient.restore(id);
  });

  const restore = async () => {
    restoreOutcomeMutation.mutate(outcome.id, {
      onSuccess: () => {
        archivedOutcomesQueryMutator.remove(outcome.id);
        pendingOutcomesQueryMutator.push({
          ...outcome.raw,
          status: requestOutcomeStatus.queued,
        });
      },
    });
  };

  const archive = async () => {
    const confirmed = await confirm('', {
      modal_header: {
        content: t('alerts:confirm.archive_outcome_title', {
          defaultValue: 'Archive outcome',
        })!,
      },
      modal_body: {
        content: (
          <div>
            {t('alerts:confirm.archive_outcome_text', {
              defaultValue: 'Are you sure you want to archive',
            })}{' '}
            <span className={'tw-font-bold'}>{outcome.raw.title}</span>?
          </div>
        ),
      },
    });

    if (!confirmed) {
      return;
    }

    archiveOutcomeMutation.mutate(outcome.id, {
      onSuccess: () => {
        pendingOutcomesQueryMutator.remove(outcome.id);
        archivedOutcomesQueryMutator.push({
          ...outcome.raw,
          status: requestOutcomeStatus.archived,
        });
      },
    });
  };

  return (
    <CollapsibleInnerCard
      open={open}
      variant={'subtle'}
      className={cn('tw-relative tw-flex tw-flex-col tw-rounded-lg', className)}
      title={
        <>
          {cardTop}
          <div className={'tw-flex tw-gap-2'}>
            <RequestOutcomePriority
              size={'xs'}
              variant={outcome.raw.is_default ? 'default' : 'additional'}
            >
              {outcome.raw.sort}
            </RequestOutcomePriority>

            <div className={'tw-flex tw-grow tw-items-center'}>
              <div className={'tw-grow tw-items-center'}>
                <div className={'tw-flex tw-items-center'}>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className={'tw-line-clamp-3 tw-break-all'}>{outcome.raw.title}</div>
                      </TooltipTrigger>
                      <TooltipContent className={'tw-max-w-96 tw-overflow-x-auto tw-break-all'}>
                        {outcome.raw.title}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      {...props}
    >
      <If when={archiveOutcomeMutation.isLoading || restoreOutcomeMutation.isLoading}>
        <div
          className={
            'tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-bg-background/70'
          }
        >
          <Loader2 size={30} className={'tw-animate-spin tw-text-primary'} />
        </div>
      </If>

      <CollapsibleInnerContent>
        <CollapsibleInnerCardContent>
          <div className={'tw-flex tw-flex-col tw-gap-3'}>
            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>
                {t('attributes/request:status', {
                  defaultValue: 'Status',
                })}
              </span>
              <RequestStatusBadge variant={outcome.raw.status}>
                {t(requestStatusTranslationsMap(outcome.raw.status))}
              </RequestStatusBadge>
            </div>

            {outcome.raw.video_template?.title && (
              <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
                <span className={'tw-font-medium tw-text-text-strong'}>{t('Style name')}</span>
                <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                  {outcome.raw.video_template?.title}
                </span>
              </div>
            )}

            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>
                {t('common:video_type', {
                  defaultValue: 'Video type',
                })}
              </span>
              <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                {t(`models/request:type.${outcome.raw.type}`, {
                  defaultValue: t('common:unknown'),
                })}
              </span>
            </div>

            <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>
                {t('common:total_length', {
                  defaultValue: 'Total length',
                })}
              </span>
              <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                {outcome.totalLength || t('common:unset')}
              </span>
            </div>

            {outcome.raw.aspect_ratio && (
              <div className={'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-py-2'}>
                <span className={'tw-font-medium tw-text-text-strong'}>{t('Video size')}</span>
                <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                  {outcome.raw.aspect_ratio}
                </span>
              </div>
            )}

            <div className={'tw-flex tw-flex-col tw-gap-2 tw-py-2'}>
              <span className={'tw-font-medium tw-text-text-strong'}>
                {t('common:description', {
                  defaultValue: 'Description',
                })}
              </span>
              <span className={'tw-min-w-0 tw-break-words tw-text-text-strong'}>
                {outcome.raw.description || t('common:unset')}
              </span>
            </div>

            <div
              className={
                'tw-flex tw-items-center tw-justify-between tw-border-t tw-border-neutral-200 tw-py-2'
              }
            >
              <div className={'tw-text-sm tw-text-text-inactive'}>
                {t(`models/request:outcome.created_by.${outcome.raw.created_by}`, {
                  defaultValue: 'Created by unknown',
                })}
              </div>

              {!request.isCompleted && (
                <TooltipProvider>
                  {outcome.canArchive ? (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant={'ghost'}
                          size={'icon'}
                          disabled={archiveOutcomeMutation.isLoading}
                          onClick={archive}
                        >
                          <ArchiveX size={20} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('common:archive', {
                          defaultValue: 'Archive',
                        })}
                      </TooltipContent>
                    </Tooltip>
                  ) : outcome.canRestore ? (
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger asChild>
                        <Button
                          variant={'ghost'}
                          size={'icon'}
                          disabled={restoreOutcomeMutation.isLoading}
                          onClick={restore}
                        >
                          <ArchiveRestore size={20} />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        {t('common:restore', {
                          defaultValue: 'Restore',
                        })}
                      </TooltipContent>
                    </Tooltip>
                  ) : null}
                </TooltipProvider>
              )}
            </div>
          </div>
        </CollapsibleInnerCardContent>
      </CollapsibleInnerContent>
    </CollapsibleInnerCard>
  );
};

export { RequestPendingOutcomeCard };
