import React from 'react';
import { Button } from 'src/components/ui/button/button';
import { cn } from 'src/lib/utils';
import { useTranslation } from 'react-i18next';
import { TablePageSizeDropdown } from 'src/components/Table/table-page-size-dropdown';
import { useTableStore } from 'src/components/Table/useTableStore';
import { If } from 'src/components/If';
import { Skeleton } from 'src/components/ui/skeleton';
import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight } from 'lucide-react';

type Props = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  className?: string;
};

const TableNavigator: React.FC<Props> = ({
  totalCount,
  pageSize,
  currentPage,
  onPageChange,
  className,
}) => {
  const { t } = useTranslation('pagination');

  const { isLoading } = useTableStore();

  const pageStartIndex = totalCount > 0 ? (currentPage - 1) * pageSize + 1 : 0;
  const pageEndIndex = Math.min(currentPage * pageSize, totalCount);
  const firstPage = 0;
  const lastPage = Math.ceil(totalCount / pageSize);
  const hasNextPage = currentPage !== lastPage && lastPage !== 0;
  const hasPreviousPage = currentPage !== 1;

  const onFirst = () => {
    if (currentPage === firstPage) {
      return;
    }

    onPageChange(firstPage);
  };

  const onNext = () => {
    if (currentPage === lastPage) {
      return;
    }

    onPageChange(currentPage);
  };

  const onPrevious = () => {
    if (currentPage === 1) {
      return;
    }

    onPageChange(currentPage - 2);
  };

  const onLast = () => {
    if (currentPage === lastPage) {
      return;
    }

    onPageChange(lastPage);
  };

  return (
    <div className={cn('tw-flex tw-items-center tw-justify-between tw-gap-4', className)}>
      <div className={'tw-flex tw-items-center tw-gap-4 tw-text-text md:tw-gap-6'}>
        <div className={'tw-hidden md:tw-block'}>
          <If when={!isLoading} else={<Skeleton className={'tw-h-5 tw-w-20'} />}>
            <div>
              {t('showing_entries', {
                replace: { from: pageStartIndex, to: pageEndIndex, count: totalCount },
                defaultValue: ':from - :to of :count',
              })}
            </div>
          </If>
        </div>

        <TablePageSizeDropdown />
      </div>
      <div className={'tw-flex tw-items-center tw-gap-2'}>
        <Button
          type={'button'}
          variant={'outline'}
          disabled={!hasPreviousPage || isLoading}
          size={'small'}
          onClick={onFirst}
        >
          <ChevronFirst size={20} />
          <span className={'tw-sr-only'}>{t('first', { defaultValue: 'First' })}</span>
        </Button>
        <Button
          type={'button'}
          variant={'outline'}
          disabled={!hasPreviousPage || isLoading}
          size={'iconStartSmResponsive'}
          onClick={onPrevious}
        >
          <ChevronLeft size={20} />
          <span className={'tw-hidden md:tw-block'}>
            {t('previous', { defaultValue: 'Previous' })}
          </span>
          <span className={'tw-sr-only'}>{t('previous', { defaultValue: 'Previous' })}</span>
        </Button>

        <div className={'tw-py-2 tw-font-medium tw-text-text-subtle md:tw-hidden'}>
          {currentPage}
        </div>

        <Button
          type={'button'}
          variant={'outline'}
          disabled={!hasNextPage || isLoading}
          size={'iconEndSmResponsive'}
          onClick={onNext}
        >
          <span className={'tw-hidden md:tw-block'}>{t('next', { defaultValue: 'Next' })}</span>
          <ChevronRight size={20} />
          <span className={'tw-sr-only'}>{t('next', { defaultValue: 'Next' })}</span>
        </Button>
        <Button
          type={'button'}
          variant={'outline'}
          disabled={!hasNextPage || isLoading}
          size={'small'}
          onClick={onLast}
        >
          <ChevronLast size={20} />
          <span className={'tw-sr-only'}>{t('last', { defaultValue: 'Last' })}</span>
        </Button>
      </div>
    </div>
  );
};

export { TableNavigator };
