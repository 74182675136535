import React, { useRef } from 'react';
import { array, boolean, object, string } from 'yup';
import { extractHookFormErrors } from 'src/components/Form/Form.utils';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useVideoProgressActions } from 'src/features/video-player/use-video-progress-store';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestRevisionVideoPlayerGlobalStore } from 'src/features/revisions/use-request-revision-video-player-global-store';
import { shallow } from 'zustand/shallow';
import { yupResolver } from '@hookform/resolvers/yup';
import { Textarea } from 'src/components/ui/textarea';
import EmojiPicker from 'src/components/ui/emoji-picker';
import { Button } from 'src/components/ui/button/button';
import { Smile, UploadCloud } from 'lucide-react';
import { RevisionChatSelectedMedia } from './revision-chat-selected-media';
import { DropzonePopoverInput } from 'src/components/Form/S3UploadInput/hook-form/dropzone-popover-input';
import { ElapsedTimeBadge } from './elapsed-time-badge';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { useShallow } from 'zustand/react/shallow';
import { StoreCommentFormObject } from 'src/pages/RevisionsPage/revisions-content-main/revision-chat-content.types';
import { ButtonLoader } from 'src/components/ui/loader';
import { useBreakpoint } from 'src/lib/hooks';

type Props = {
  className?: string;
  children?: React.ReactNode;
  commentMutation: any;
  onSubmit?: () => void;
  replyCommentId?: string;
};

const validationSchema = object().shape({
  include_time: boolean(),
  media: array().nullable(),
  comment: string()
    .nullable()
    .when('media', {
      is: (media: any) => {
        return !media.length;
      },
      then: string().required('Field is required'),
    }),
});

const RevisionChatInputForm: React.FC<Props> = ({
  className,
  replyCommentId,
  commentMutation,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoint();

  const { selectedRevision, selectedRevisionMedia } = useRequestRevisionsStore();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { status } = useMediaUploadStore(useShallow(({ status }) => ({ status })));

  const { isLatest = false } = selectedRevision ?? {};

  const defaultValues = {
    comment: '',
    include_time: !!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio) && isLatest,
    media: [],
  };

  // :)
  const { getPlayedSeconds } = useVideoProgressActions();

  const pageStore = useRequestRevisionVideoPlayerGlobalStore(
    ({ timeFormat, pause }) => ({
      timeFormat,
      pause,
    }),
    shallow,
  );

  const form = useForm<StoreCommentFormObject>({
    defaultValues,
    /* eslint-disable */
    //@ts-ignore
    resolver: yupResolver(validationSchema),
  });

  const includeTime = form.watch('include_time');
  const comment = form.watch('comment');
  const media = form.watch('media');

  const mediaUploading = (media ?? []).some((m) => status[m.id]?.isUploading);
  const uploadsFailed = (media ?? []).some((m) => status[m.id]?.failed);

  const submit = async (values: StoreCommentFormObject) => {
    commentMutation
      .mutateAsync([
        replyCommentId ? replyCommentId : selectedRevision!.id,
        {
          ...values,
          revision_timestamp:
            !!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio) &&
            isLatest &&
            values.include_time
              ? getPlayedSeconds()
              : undefined,
          media_id: selectedRevisionMedia?.id,
        },
      ])
      .catch((e: any) => {
        const errors = extractHookFormErrors(e) ?? {};

        console.log(errors);
        Object.keys(errors).forEach((key) => {
          form.setError(key as any, { type: 'custom', message: errors[key] });
        });
      })
      .finally(() => {
        form.reset();
        onSubmit?.();
      });
  };

  const handleEmojiSelect = (emoji: any) => {
    if (!textareaRef.current) {
      return;
    }
    const cursorPos = textareaRef.current?.selectionStart;
    const textBeforeCursor = comment?.slice(0, cursorPos);
    const textAfterCursor = comment?.slice(cursorPos);

    const newText = textBeforeCursor + emoji.native + textAfterCursor;
    form.setValue('comment', newText);

    // Set cursor after inserted emoji
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.selectionStart = cursorPos + emoji.native.length;
      }
    }, 0);
  };

  return (
    <div className={className}>
      <FormProvider {...form}>
        <RevisionChatSelectedMedia name={'media'} />
        <form onSubmit={form.handleSubmit(submit)}>
          <div>
            <Textarea
              id={'comment'}
              rows={isXs ? 2 : 6}
              placeholder={t('Type a message...')!}
              onKeyDown={(e) => {
                if (includeTime) {
                  pageStore.pause();
                }

                if (e.shiftKey || e.code !== 'Enter') {
                  return;
                }

                if (
                  form.formState.isSubmitting ||
                  mediaUploading ||
                  uploadsFailed ||
                  commentMutation.isLoading
                ) {
                  return;
                }

                e.preventDefault();

                form.handleSubmit(submit)();
              }}
              {...form.register('comment')}
              ref={(e) => {
                form.register('comment').ref(e);
                textareaRef.current = e;
              }}
            />
          </div>
          <div className={'tw-mt-2 tw-flex tw-items-center tw-gap-2'}>
            <DropzonePopoverInput name={'media'} model={'request_revision_comment'}>
              <Button type={'button'} variant={'outline'} size={'sm'}>
                <UploadCloud size={18} />
              </Button>
            </DropzonePopoverInput>
            <EmojiPicker onSelect={handleEmojiSelect}>
              <Button type={'button'} variant={'outline'} size={'sm'}>
                <Smile size={18} />
              </Button>
            </EmojiPicker>

            <ElapsedTimeBadge
              title={t('Check the box to include current playback time in the message!')!}
              disabled={!(selectedRevisionMedia?.isVideo || selectedRevisionMedia?.isAudio)}
              formattingOptions={{
                format: pageStore.timeFormat,
                fps: selectedRevisionMedia?.fps ?? 24,
              }}
            />

            <Button
              variant={'brand'}
              className={'tw-ml-auto'}
              size={'sm'}
              disabled={
                form.formState.isSubmitting ||
                mediaUploading ||
                uploadsFailed ||
                commentMutation.isLoading
              }
              type={'submit'}
            >
              {!form.formState.isSubmitting && !commentMutation.isLoading && t('Send')}
              <ButtonLoader
                className={'tw-ms-0'}
                loading={form.formState.isSubmitting || commentMutation.isLoading}
              />
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export { RevisionChatInputForm };
